@import "~bootstrap/scss/bootstrap";



.headerService {
    font-family: Stencil-Bt, Arial, serif;
    font-size: 16px;
    padding-left: 5px;
    text-align: center;
    align-self: center;
}