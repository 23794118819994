@import "~bootstrap/scss/bootstrap";
.loginBackground {
    background: none;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100vw;
    height: 100vh;
}

.loginContainer {
    background:    url('../../assets/Images/loginTop.png'), url('../../assets/Images/loginBottom.png');
    background-size:     cover;                      /* <------ */
    background-repeat:   no-repeat;
    background-position: left top, right bottom; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    
}

.login {
    width: 100%; 
    margin: 10px; 
    margin-top: 20px;
    border-radius: 20px; 
    height: 82vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

@include media-breakpoint-up(md) {
    .loginBackground {
        //background: url('./assets/Images/loginImage.jpg') no-repeat center center fixed;
        background-image:    url('../../assets/Images/loginImage.jpg');
        background-size:     cover;                      /* <------ */
        background-repeat:   no-repeat;
        background-position: center center; 
    }

    .login {
        width: 50%; 
        margin: 80px; 
        margin-right: 50px; 
    }

}

@include media-breakpoint-up(lg) {
    .login {
        width: 50%; 
        margin: 80px; 
        margin-right: 50px; 
    }

}

@include media-breakpoint-up(xl) {
    .login {
        width: 35%; 
        margin: 80px; 
        margin-right: 150px; 
    }

}

.shadowInput {
    box-shadow:inset 0 0 5px 5px #E0E0E0;
    font-family: Avenir-Black, Arial, serif;
    color:'#000000'
}

.forgetPass {
    padding-right: 30px;
    text-decoration: none;
    color: #D42F45;
    font-family: Avenir, Arial, serif;
    margin-top: 5px;
    font-size: 14px;
}

.loginButton{
    background: #D42F45 0% 0% no-repeat padding-box;
    border-radius: 17px;
    color: #fff;
    font-family: Stencil-Bt, Arial, serif;
    border: none;
    width: 200px;
    height: 50px;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 100px;
}

.footer {
    position: absolute;
    bottom: 20px;
    width: 100%;
}

.copyright {
    font-family: HelveticaNowText, Arial, serif;
    color: #000000;
    font-size: x-small;
}

.diginest {
    color: #D42F45;
    font-family: HelveticaNowText, Arial, serif;
    font-size: x-small;
    margin-left: 2px;
}