@import "~bootstrap/scss/bootstrap";
.headerService {
    font-family: Stencil-Bt, Arial, serif;
    font-size: 20px;
    padding-left: 5px;
}
.headerCategory
{
    font-family: Stencil-Bt, Arial, serif;
    font-size: 20px;
    padding-left: 5px;
    color:#FFFFFF ;
}
.workDays
{
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    background-color: #417AE80A !important;
    color: #1B2F5B;
    border-color: #417AE85E;
}
.time
{
    font-weight: bolder !important;
    color:#1B2F5B ;
    background-color: #FFFFFF;
    border-color: #B4CBF7;
    border-radius: 10px;
}
