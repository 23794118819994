@import "~bootstrap/scss/bootstrap";

.orderList {
    border: 1px solid #417AE85E;
    border-radius: 14px;
    background: #417AE80A 0% 0% no-repeat padding-box;
    min-height: 100px;
    padding: 5px;
}

.orderInput {
    background: transparent;
    border: 0px;
}

.searchResult {
    background: #FFF 0% 0% no-repeat padding-box;
    z-index: 10px;
    border: 1px solid green;
    position: absolute;
    top: 50;
    width: 300px;
    height: 70px;
    overflow-y: scroll;
}

.itemStyle {
    border: 1px solid black; 
    padding: 3px;
    font-size: small;
}