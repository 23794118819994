@import "~bootstrap/scss/bootstrap";

.headerText {
    font-family: Stencil-Bt, Arial, serif;
    font-size: x-large;
    padding-left: 5px;
}
.modalsmallTitle
{
    
    font-family: Stencil-Bt, Arial, serif;
    font-size: 30px;
    letter-spacing: 2.5px;
    color: #FFFFFF;
    position: absolute;
   
}
.username {
    font-family: Avenir-Black, Arial, serif;
    color: #565656;
}

.role {
    font-family: Avenir, Arial, serif;
    color: #9E9E9E;
    font-size: 12px;
}

.headerStyle {
    width: 100%; 
    height: 80px;  
    border-radius: 20px;
}

@include media-breakpoint-up(md) {
    .headerStyle {
        width: 100%;
    }
}

@include media-breakpoint-up(lg) {
    .headerStyle {
        width: 80%;
    }
}

@include media-breakpoint-up(xl) {
    .headerStyle {
        width: 100%;
    }
}

.searchText
{
  font-family: Stencil-Bt, Arial, serif;
  font-size: 20px;
  width: 50px;
  height: 50px;
  border-radius: 20px;
  background-color: #4AAD47;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.my-modal{
    width: 80vw !important;
    max-width: 80vw !important;
}


.react-tag-input {
    border-radius: 10px !important;
    background-color:#417AE80A !important;
    color:#1B2F5B; 
    border-color:#417AE85E;
}

.react-select{
    border-radius: 10px !important;
}

.box{
    height:50px;
    width:50px;
    border-radius: 25px;
    position: absolute;
    right: -15px;
    top: -15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background:linear-gradient(#51F0EB,transparent);
    background-color:#9200B0 /*this your primary color*/
}

.cash{
    display: flex;
    justify-content: center;
    align-items: center;
    height:40px;
    width:40px;
    border-radius: 20px;
    cursor: pointer;
    background: transparent linear-gradient(180deg, #7AFF70 0%, #12D304 100%) 0% 0% no-repeat padding-box;
}
.group{
    display: flex;
    justify-content: center;
    align-items: center;
    height:40px;
    width:40px;
    border-radius: 20px;
    cursor: pointer;
    background: transparent linear-gradient(180deg, #92B7FF 0%, #0065FC 100%) 0% 0% no-repeat padding-box;
    margin-bottom: 10px;
}
.times{
    display: flex;
    justify-content: center;
    align-items: center;
    height:40px;
    width:40px;
    border-radius: 20px;
    cursor: pointer;
    background: transparent linear-gradient(180deg, #FFC983 0%, #F78C00 100%) 0% 0% no-repeat padding-box;
    margin-bottom: 10px;
}
::placeholder {
    color: #545454;
    opacity: 50%; 
    font-family: Stencil-Bt, Arial, serif;
    align-items: flex-start;
  }