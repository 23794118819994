.download
{
    color: #417AE8;
}
.headerReport {
    font-family: Stencil-Bt, Arial, serif;
    font-size: 17px;
    padding-left: 5px;
}
.CustomerText
{
  font-family: Stencil-Bt, Arial, serif;
  font-size: 20px;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: #4AAD47;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}