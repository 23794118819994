@import "~bootstrap/scss/bootstrap";

.iconStyle {
    display: none;
    font-family: Avenir-Black, Regular;
    margin-left: 10px;
    font-size: 12.5px;
    color: #545454;
}



.link {
    text-decoration : none;
    margin: 9px;
    
}

.menus {
    height: 100%;
    display: flex;
    flex-direction: column;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll; 
}

.menus::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}

.buttonStyle {
    //background: #D42F45 0% 0% no-repeat padding-box;
    background-color: #E5FAE4;
    font-family: Stencil-Bt, Arial, serif;
    border: none;
    position: fixed;
    bottom: 0px;
    height: 50px;
    width: 100%;
    color: #fff;
    font-size: x-large;
    display: flex;
    align-items: flex-start;
}

.topStyle {
    //background: #D42F45 0% 0% no-repeat padding-box;
    background-color: #fff;
    font-family: Stencil-Bt, Arial, serif;
    border: none;
    position: fixed;
    top: 0px;
    height: 50px;
    width: 30%;
    color: #fff;
    font-size: x-large;
    display: flex;
    align-items: flex-start;
}

.red {
    border: none;
    background: #D42F45 0% 0% no-repeat padding-box;
    height: 100%;
    width:50%;
    display: flex;
    align-items: flex-start;
    margin: 0px;
    padding: 0px;
    color: #fff;
    font-size: x-large;
}


.buttonText{
    display: none;
}

.bodyStyle {
    z-index: 1;
    background-color: #E5FAE4;
    height: 100vh;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    //border-radius: 50px;
    position: relative;
    left: -20px;
    padding: 10px;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
}

.bodyStyle::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}

.imageStyle {
    width: 35px; 
    height: 35px;
    margin-top: 20px;
    margin-bottom: 20px;
    z-index: 1;
}

@include media-breakpoint-up(md) {
    .iconStyle {
        display: inline;
    }

    .imageStyle {
        margin-left: 15%;
        width: 60%; 
        height: 20%;
    }

    .buttonText{
        display: inline;
        margin-top: 10px;
        margin-left: 10px;
    }

    .buttonStyle {
        width: 100%;
        align-items: flex-start;
    }

    .bodyStyle{
        left: -20px
    }
    .menus {
        height: 76vh;
        padding-bottom: 50px;
    }

    .logout {
        margin-left: 30px;
    }
}

.selected {
    width: 0;
	height: 0;
	border-top: 15px solid transparent;
	border-right: 20px solid #E5FAE4;
	border-bottom: 15px solid transparent;
    right: 0px;
    position: relative;
    right: -2px;
}

.menuItem {
    display: flex;
    flex-direction:row;
    justify-content:space-between; 
    align-items:'center';
}


