@import "~bootstrap/scss/bootstrap";


.ordertotal {
    font-family: Avenir-Black, Arial, serif;
    font-size: 18px;
    color: #1B2F5B;
}

.order {
    font-family: Avenir-Black, Arial, serif;
    font-size: 26px;
    color: #1B2F5B;
}

.orderCount {
    color: #D42F45;
    font-family: Avenir-Black, Arial, serif;
    font-size: 35px;
    padding-top: 20px;
}

.totalStore {
    font-family: productSansBold, Arial, serif;
    font-size: 20px;
    color: #1B2F5B;
    margin-left: 20px;
}

.storeCount{
    font-family: productSansBold, Arial, serif;
    font-size: 34px;
    text-align: center;
    color: #D42F45;
}

.customerCount {
    color: #D42F45;
    font-family: Avenir-Black, Arial, serif;
    font-size: 35px;
    padding-top: 5px;
}

.customerCard {
    background: transparent linear-gradient(40deg, #FFF2F2 0%, #DE7575 500%) 0% 0% no-repeat padding-box;
}

.totalCus {
    color: #D88787;
    font-family: productSansBold, Arial, serif;
    font-size: 20px;
    margin-left: 20px;
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.cusTotal {
    color: #D88787;
    font-family: productSansBold, Arial, serif;
    font-size: 30px;
    margin-left: 20px;
    margin-top: 0px;
    padding-top: 0px;
}

.productCard {
    background: transparent linear-gradient(32deg, #DFFEF2 0%, #6C9B88 500%) 0% 0% no-repeat padding-box;
}

.totProducts {
    color: #2BC98A;
    font-family: productSans, Arial, serif;
    font-size: 30px;
}

.products {
    color: #2BC98A;
    font-family: productSansBold, Arial, serif;
    font-size: 24px;
    margin-left: 10px;
}

.prodIcon {
    background: transparent radial-gradient(closest-side at 50% 50%, #EECD73 0%, #CB9F26 100%) 0% 0% no-repeat padding-box;
    width: 40px;
    height: 40px; 
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.totalProducts {
    color: #D42F45;
    font-family: Avenir-Black, Arial, serif;
    margin-left: 10px;
    font-size: x-large;
}

.torder{
    font-family: productSansBold, Arial, serif;
    color: '#1B2F5B'
}

.tabHeader {
    border: 1px solid #AAB6CC;
    text-align: center;
    color: #8C989A;
    font-family: productSansBold, Arial, serif;
}



.viewmore {
    text-align: right;
    color: #D42F45;
    font-family: Avenir-Black, Arial, serif;
    cursor: pointer;
    font-size: 14px;
}

.tableStyle {
    overflow-x:auto;
}

.recentOrder{
    text-align: right;
    color: #1B2F5B;
    font-family: Avenir-Black, Arial, serif;
    cursor: pointer;
    font-size: 22px;
    margin-bottom: 15px;
}


