.divider {
    width: 1px;
    background-color: grey;
    margin-left: 10px;
    margin-right: 7px;
    height: 1cm;
  }
  .checkText
{
  font-family: Stencil-Bt, Arial, serif;
  font-size: 20px;
  width: 55px;
  height: 55px;
  border-radius: 20px;
  background-color: #4AAD47;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}