.headerNotification
{
    font-family: Stencil-Bt, Arial, serif;
    font-size: 17px;
    padding-left: 5px;
}
#inputID::placeholder {
    color: #417AE8;
    opacity: 1;
    font-size: 15px;
    font-family: Avenir-Black, Arial, serif;
}