@import "~bootstrap/scss/bootstrap";
.headerReview {
    font-family: Stencil-Bt, Arial, serif;
    
    
}
  .star {
    color: #FE4040;
    border-color: #FE4040;
  }
  