@import "~bootstrap/scss/bootstrap";
.headerPayout {
    font-family: Stencil-Bt, Arial, serif;
    
    
}

.paid {
    color: #26CE1A;
    font-family: Avenir-Black, Arial, serif;
}

.processing {
    color: #1A62CE;
    font-family: Avenir-Black, Arial, serif;
}

.pending {
    color: #F78C00;
    font-family: Avenir-Black, Arial, serif;
}
.iconlist
{
    color: #9200B0;
}
.dropdown-content .firsticon {
  
  text-decoration: none;
  display: block;
  color: #F78C00;

  }
  .secondicon
  {
    text-decoration: none;
    display: block;
    color: #0065FC;
  }
  .thirdicon
  {
    text-decoration: none;
    display: block;
    color: #12D304;
  }
  