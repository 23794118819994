@import "~bootstrap/scss/bootstrap";


.approved {
    color: #4AAD47;
    font-family: Avenir-Black, Arial, serif;
}

.rejected {
    color: #D42F45;
    font-family: Avenir-Black, Arial, serif;
}

.headerService {
    font-family: Stencil-Bt, Arial, serif;
    font-size: 20px;
    padding-left: 5px;
}