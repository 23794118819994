@import "~bootstrap/scss/bootstrap";

.createButton{
    background: #D42F45 0% 0% no-repeat padding-box;
    border-radius: 17px;
    color: #fff;
    font-family: Stencil-Bt, Arial, serif;
    border: none;
    width: 200px;
    height: 60px;
    font-size: 21px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.store
{
    background: #D42F45 0% 0% no-repeat padding-box;
    border-radius: 17px;
    color: #fff;
    font-family: Stencil-Bt, Arial, serif;
    border: none;
    width: 100%;
    height: 60px;
    font-size: 21px;
    margin-top: 20px;
    margin-bottom: 10px; 
}
table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  thead
  {
    
    background: #FFF2D5 0% 0% no-repeat padding-box !important;
    border-bottom-width: 0 !important;
    border-bottom: none !important;
  }
  
  td ,th{
    text-align: left;
  }
  
  tr:nth-child(even) {
    background-color: #E6E6E6;
    opacity: 100%;
  }
  





.searchButton
{
  float: right;
  padding: 11px 13.5px;
  
  background: #4AAD47 0% 0% no-repeat padding-box;
  font-size: 17px;
  border-radius: 20px;
  opacity: 1;
  box-shadow: 0px 10px 20px #C4C8D066;

}
.searchStyle {
  width: 25%; 
  height: 50px;  
  border-radius: 20px;
}

.tableStyle {
 
  width: 100%; 
  
}

@include media-breakpoint-up(md) {
  .tableStyle {
      width: 100%;
  }
}

@include media-breakpoint-up(lg) {
  .tableStyle {
      width: 100%;
  }
}

@include media-breakpoint-up(xl) {
  .tableStyle {
      width: 100%;
  }
}
.add{
  color:#D42F45 ;
}
.green
{
  color: #B6FFB2;
}
.addButton
{
  z-index: 1;
  position: absolute;
  top: -10px;
  right: -10px;
  //float: right;
  //display: flex;
  //justify-content: flex-end;
  
}
.tableHead
{
  background: #FFF2D5 0% 0% no-repeat padding-box;
border-radius: 4px;
opacity: 1;
font-family: productSansBold, Arial, serif;
    font-size: 18px;
    color: #1B2F5B;
letter-spacing: 0px;
}
.tableBody
{
  
font-family: productSans, Arial, serif;
    font-size: 16px;
    color: #1B2F5B;
    letter-spacing: 0px;
}
.pagination
{
  float: right;
  display: flex;
  justify-content: flex-end;
  
font-family: SourceSansPro, Arial, serif;
font-size: 13px;
color: #D42F45 !important;
letter-spacing: 0px;

}
.pag{
  color: #D42F45 !important;  
}
.page-item.active .page-link
{
  background-color:#D42F45 !important;
  color: white !important;
}



.formdeleteLabel
{
  font-family: Avenir-Black, Arial, serif;
    font-size: 30px;
    color: #1B2F5B;
    letter-spacing: 0px;
    margin-left: 10px;
}

// .form-control {
 
//  // height: 54px;
//   background: #417AE80A 0% 0% no-repeat padding-box !important;
//   border: 1px solid #417AE85E !important;
//   //border-radius: 14px !important;
//   color: #1B2F5B !important;
//   letter-spacing: 0px;
//   font-family: productSans, Arial, serif;
//   font-size: 18px;
//   border-top-right-radius:14px !important;
//   border-bottom-right-radius:14px !important;
// }
// .form-select
// {
//   border-radius: 14px !important;
//   border: 1px solid #417AE85E !important;
//   color: #1B2F5B !important;
// }


.modal-content { 
   background: #FFFFFF 0% 0% no-repeat padding-box !important;
box-shadow: 0px 3px 6px #7c6c6c29 !important;
border-radius: 40px !important;
}
.loc
{
  color:#417AE8;
}
// .addSubmit
// {
//   font-family: Avenir-Black, Arial, serif;
//     font-size: 38px;
    
//     letter-spacing: 0px;
//     color:#D42F45 !important;
//     margin-left :40px;
// }



.workStyle {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  background-color:#417AE80A !important;
  color:#1B2F5B; 
  border-color:#417AE85E;
}

#delivery_status, #status{
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-color: red;
}
#delivery_status:checked, #status:checked{
  background-color: #28a745;
}


.deleteText {
    font-family: Avenir-Black, Arial, serif;
    font-size: 26px;
    color: #1B2F5B;
    text-align: center;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

.headerService {
  font-family: Stencil-Bt, Arial, serif;
  font-size: 20px;
  padding-left: 5px;
  word-wrap: unset;
}
