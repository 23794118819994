@import "~bootstrap/scss/bootstrap";

@font-face {
    font-family: 'Avenir-Book';
    src: local('Avenir'), url(./assets/fonts/AvenirLTStd-Book.otf) format('opentype');
}
  
@font-face {
    font-family: 'Avenir-Black';
    font-weight: 900;
    src: local('Avenir'), url(./assets/fonts/AvenirLTStd-Black.otf) format('opentype');
}
  
@font-face {
    font-family: 'Avenir';
    font-weight: 900;
    src: local('Avenir'), url(./assets/fonts/AvenirLTStd-Roman.otf) format('opentype');
}

@font-face {
    font-family: 'Stencil';
    font-weight: 900;
    src: local('Stencil'), url(./assets/fonts/stencil.ttf) format('opentype');
}

@font-face {
    font-family: 'Stencil-Bt';
    font-weight: 900;
    src: local('Stencil-Bt'), url(./assets/fonts/stencil-bt.ttf) format('opentype');
}

@font-face {
    font-family: 'HelveticaNowText';
    font-weight: 900;
    src: local('HelveticaNowText'), url(./assets/fonts/HelveticaNowText-Regular.otf) format('opentype');
}

@font-face {
    font-family: 'productSansBold';
    font-weight: 900;
    src: local('productSansBold'), url(./assets/fonts/ProductSansBold.ttf) format('opentype');
}

@font-face {
    font-family: 'productSans';
    font-weight: 900;
    src: local('productSans'), url(./assets/fonts/ProductSansRegular.ttf) format('opentype');
}

.tableHeader {
    text-align: center;
    color: #1B2F5B;
    font-family: productSansBold, Arial, serif;
    font-size: 18px;
}

.tableContent {
    text-align: center;
    color: #1B2F5B;
    font-family: productSans, Arial, serif;
    font-size: 16px;
    padding: 0px;
    margin: 0px;
}

tr:nth-child(even) {
    background-color: transparent !important; 
    opacity: 100%;
    text-align:center
}

// Modal CSS -------

.modalTitle
{
  font-family: Stencil-Bt, Arial, serif;
    font-size: 30px;
    letter-spacing: 2.5px;
    color: #FFFFFF;
    position: absolute;
    
}

.modalHeader
{
  background-color: #D42F45;
  border-top-right-radius:40px !important;
  border-top-left-radius:40px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.formLabel
{
    font-family: Avenir-Black, Arial, serif;
    font-size: 14px;
    color: #1B2F5B;
}

.imageStyles {
    background-color: #417AE80A;
    border: 1px solid #417AE85E;
    width: 200px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    position: relative;
}

.inputStyle{
    border-radius: 10px !important;
    background-color:#417AE80A !important;
    color:#1B2F5B; 
    border: 1px solid #417AE85E;
}

input:focus{
    outline: none;
}

.inputForm
{
    border-radius: 0px !important;
}

.formStyle {
    margin-top: 10px;
    border-radius: 20px;
}

.loginButton{
    background: #D42F45 0% 0% no-repeat padding-box;
    border-radius: 17px;
    color: #fff;
    font-family: Stencil-Bt, Arial, serif;
    border: none;
    width: 200px;
    height: 50px;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 100px;
}
  
.error{
    color: 'red'
}






