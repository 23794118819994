@import "~bootstrap/scss/bootstrap";


.applicable {
    color: #1B2F5B;
    font-family: Avenir-Black, Arial, serif;
    font-size: 26px;
    margin-bottom: 20px;
}
.inputareaCoupon {
    height: 154px !important;
    width: 33% !important;
    border-radius: 14px !important;
    background-color:#417AE80A !important;
    color:#1B2F5B !important; 
    border-color:#417AE85E !important;
  }
.shop
{
    color:grey;
    
}